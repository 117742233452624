// Useful: https://phrase.com/blog/posts/nuxt-js-tutorial-i18n/#using-custom-date-formats
import _ from 'lodash'
import datetimeFormats from '../.app/locales/datetimeFormats'

// Import locales from the app layer
import en from '../.app/locales/en.json'
import nl from '../.app/locales/nl.json'
// Import locales from this layer
import volunteerlyEn from './locales/volunteerly.en.json'
import volunteerlyNL from './locales/volunteerly.nl.json'

// Utility function to merge localizations
function mergeLocalizations(defaultLocales: any, customLocales: any) {
  return _.merge({}, defaultLocales, customLocales)
}

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'nl',
  availableLocales: ['en', 'nl'],
  fallbackLocale: 'nl',
  strategy: 'no_prefix',
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'i18n_redirected',
    redirectOn: 'root', // recommended
  },
  messages: {
    en: mergeLocalizations(en, volunteerlyEn),
    nl: mergeLocalizations(nl, volunteerlyNL),
  },
  datetimeFormats,
}))
